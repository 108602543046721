<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Select Default Printer</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <div class="input_group">
            <select
              class="form-control"
              name="printer"
              id="printer"
              [(ngModel)]="selectedPrinter"
            >
              <option disabled [ngValue]="undefined">
                {{ "pos.select_printer" | translate }}
              </option>
              <option *ngFor="let printer of printers" [ngValue]="printer">
                {{ printer }}
              </option>
            </select>
        </div>
        
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">

            <button mat-raised-button 
                mat-dialog-close>
                Cancel
            </button>

            <button mat-raised-button
                class="primary_btn"
                (click)="save()">
                Save & Print
            </button>

        </mat-dialog-actions>
    </div>

</div>