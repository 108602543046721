import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';
import { finalize } from 'rxjs/operators'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tax-ledger',
  templateUrl: './tax-ledger.component.html',
  styleUrls: ['./tax-ledger.component.scss']
})
export class TaxLedgerComponent implements OnInit {
  params: any = {};
  loading = true;
  ledgerData: any;
  startDate: any;
  endDate: any;
  headerName = '';
  qParams: any = {}

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.startDateCalc();
    this.params = this.route.snapshot.params;
    this.qParams = this.route.snapshot.queryParams;
    this.headerName = this.qParams.taxName;
    this.getLedger();
  }

  getLedger() {
    this.accountsService.getTaxesLedger(this.params.hotelId, this.startDate, this.endDate, this.qParams.taxType, this.qParams.taxName)
    .subscribe(res => {
      if (res?.success) {
        this.ledgerData = res?.data;
      }
    })
  }

  startDateCalc() {
    this.endDate = this.datePipe.transform((new Date()), 'y-MM-dd');
    this.startDate = new Date(this.endDate);
    this.startDate.setDate(this.startDate.getDate() - 30);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }

  goBack() {
    this.router.navigate([`accounts/${this.params.hotelId}`]);
  }
}
