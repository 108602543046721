<div>
    <div class="heading d-flex justify-content-between align-items-center">
        <h2>Ledger - {{headerName}}</h2>

        <button mat-raised-button class="active" (click)="goBack()">
            Back
        </button>
    </div>
    <mat-divider></mat-divider>
    
    <div class="container">

        <div class="row pt-1 pb-2 bg-white">
            <div class="col-sm-2">
                <label for="From Date">From Date:</label>
                <input type="date" class="form-control" id="from-date" max="{{endDate}}" [(ngModel)]="startDate" (change)="getLedger()">
            </div>
            <div class="col-sm-2">
                <label for="To Date">To Date:</label>
                <input type="date" class="form-control" id="to-date" min="{{startDate}}" [(ngModel)]="endDate" (change)="getLedger()">
            </div>
        </div>

        <table class="table mt-3">
            <thead class="thead-dark">
                <tr>
                    <th>Date</th>
                    <th *ngIf="ledgerData?.configLevel === 'chain'">Hotel</th>
                    <th>Service Name</th>
                    <th class="text-right">Service Value</th>
                    <th>Tax</th>
                </tr>
            </thead>
    
            <tbody>
                <tr *ngFor="let item of ledgerData?.items">
                    <td>{{item.date | date: 'dd MMM yyyy'}}</td>
                    <td *ngIf="ledgerData?.configLevel === 'chain'">{{item.hotelName}}</td>
                    <td>{{item.name}}</td>
                    <td class="text-right">{{item.amount}}</td>
                    <td>{{item.tax | json}}</td>
                </tr>
            </tbody>
        </table>

    </div>

</div>