import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/user.service';
import { expandCollapse, slide, rotate, staggerEffect } from 'src/app/animations';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from 'src/app/core/globals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'src/app/core/communication.service';
import { DatePipe } from '@angular/common';
import { EditStayComponent } from '../../stayview/edit/edit-stay/edit-stay.component';
import { finalize } from 'rxjs/operators';
import { LineChartComponent } from '../../charts/line-chart/line-chart.component';
import { PreviousDayCheckinsComponent } from '../previous-day-checkins/previous-day-checkins.component';
import { PaymentsService } from 'src/app/core/payments.service';
import { QuickReservationComponent } from '../quick-reservation/quick-reservation.component';

type ViewSize = 7 | 15;
@Component({
  selector: 'app-stayview2',
  templateUrl: './stayview2.component.html',
  styleUrls: ['./stayview2.component.scss'],
  animations: [expandCollapse, slide, rotate, staggerEffect]
})
export class Stayview2Component implements OnInit {
  headerDates = [];
  currentUser = '';
  currentDate: string;
  startDate: any;
  endDate: string | Date;
  hotelId: string;
  viewSize: ViewSize = 7;
  selectedDate = new Date();
  
  roomsData: any[] = [];
  roomsSummary: any[] = [];
  extraInfo: any;
  currencyCode: string;

  showBottomTable = false;
  selectingRoom = false;
  assigningGuest: any = {};

  allocationIsOpen = false;
  offset = ' 00:12:00';
  searchActive = false;
  loading = false;
  filteredGuests: any[] = [];
  features: any = {};
  syncing = false;

  // quick reservation select cells
  quickResDisabled = false;
  mouseDown = false;
  selectedCell = {
    cellIndex: {},
    selectedRow: -1,
    firstCell: -1,
    lastCell: -1,
    room: {allocations: [], roomNumId: '', roomNum: '', roomTypeId: '', roomTypeName: ''}
  }

  iconPath = '../../../../assets/img/source_icons/'
  sourceIconMapping = {
    'booking.com': 'bookingdotcom.ico',
    'Goibibo': 'goibibo.ico',
    'MakeMyTrip': 'mmt.ico',
    'Expedia': 'expedia.ico',
    'Yatra': 'yatra.ico',
    'Airbnb': 'airbbnb.ico',
    'Aiosell BE': 'aiosell.ico',
    'agoda': 'agoda.ico',
    'Hostelworld': 'hostelworld.ico'
  };
  checkedInGuests: string[] = [];
  balances = {};

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    public globals: Globals,
    private route: ActivatedRoute,
    private commService: CommunicationService,
    private datePipe: DatePipe,
    private router: Router,
    private paymentService: PaymentsService
  ) { }

  ngOnInit(): void {
    this.globals.currentUser.subscribe(user => this.currentUser = user);
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.getCurrentDate();
      this.globals.changeHotelID(this.hotelId);
    });

    this.globals.getCurrencyCode(this.hotelId)
    .subscribe(code => {
        this.currencyCode = code;
      }, err => {}
    );

    this.userService.userFeatures$.subscribe(data => {
      this.features = data;
    });
    this.commService.stayViewReload.subscribe(e => this.initializeView());
  }

  initializeView() {
    this.getStayview();
  }

  getStayview() {
    this.loading = true;
    this.checkedInGuests = [];
    this.userService.getStayview2(this.startDate, this.endDate, this.hotelId)
    .pipe(
      finalize(() => this.loading = false)
    )
    .subscribe(data => {
      if (data?.success) {
        this.headerDates = data.data.dates;
        this.roomsData = this.processRoomsData(data.data.roomsData);
        this.roomsSummary = data.data.roomsSummary;
        this.extraInfo = data.data.extraInfo;
        this.getBalances()
      }
    });
    
  }

  viewSizeToggle(size: ViewSize) {
    this.viewSize = size;
    this.endDateCalc();
    this.getStayview();
  }

  processRoomsData(roomsData: any) {
    roomsData?.forEach(roomType => {
      roomType.rooms.forEach(room => {
        if (room?.allocations?.length > 1) {
          this.sameDayCheckout(room.allocations);
          this.handleStayOverlap(room.allocations);
          room.allocations.forEach(alloc => {
            if (alloc.status === 'Checked in') {
              this.checkedInGuests.push(alloc.roomAllocationId);
            }
          })
        }
      });
    });

    return roomsData;
  }
  
  /**
   * Add offset for same day checkout 
   */
  sameDayCheckout(allocations: any[]) {
    let sameCheckoutDay: string;
    let leftOffset = 0;
    for (const alloc of allocations) {
      if (alloc.checkIn === sameCheckoutDay) {
        leftOffset++;
        alloc.leftOffset = leftOffset;
      }
      else if (alloc.checkIn === alloc.checkOut && sameCheckoutDay !== alloc.checkIn) {
        sameCheckoutDay = alloc.checkIn;
        leftOffset = 0;
      }
    }
  }

  handleStayOverlap(allocations: any[]) {
    const stays: any = {};
    let index = -1;
    for (const alloc of allocations) {
      index++;
      const key = `${alloc.checkIn}-${alloc.checkOut}`;
      if (stays[key]?.length) {
        stays[key].push(index);
      } else {
        stays[key] = [index];
      }
    }

    for (const key in stays) {
      if (stays[key]?.length > 1) {
        let status: string;
        let sameStatus = true;

        for (const i of stays[key]) {
          if (!status) {
            status = allocations[i].status;
          }
          else if (status !== allocations[i].status) {
            sameStatus = false;
          }
        }

        const last = stays[key][stays[key].length - 1];
        if (!sameStatus) {
          for (const i of stays[key]) {
            if (allocations[i].status !== 'Checked in' && allocations[i].checkIn !== allocations[i].checkOut) {
              allocations[i].hidden = true;
            }
          }
        }
        else if (status === 'Checked out' && allocations[last].checkIn !== allocations[last].checkOut) {
          allocations[last].hidden = true;
        }
      }
    }

  }

  getCurrentDate() {
    this.userService.getCurrentDate(this.hotelId).subscribe(res => {
      this.currentDate = res?.currentDate;
      this.startDate = this.currentDate;
      this.getProductConfig();
    })
  }

  getProductConfig() {
    this.userService.getProductConfig(this.hotelId)
    .subscribe(data => {
      if (data) {
        data.conf.hotelId = this.hotelId;
        data.conf.currentDate = this.currentDate;
        this.globals.setConfig(data.conf);
        localStorage.setItem('newStayview', data.conf.newStayview);
        this.endDateCalc();
        this.initializeView();
        this.quickResDisabled = data.conf.quickReservationDisabled;
      }
    });
  }

  endDateCalc(){
    this.endDate = new Date(this.startDate + this.offset);
    this.endDate.setDate(this.endDate.getDate() + (this.viewSize - 1));
    this.endDate = this.datePipe.transform((new Date(this.endDate)), 'y-MM-dd');
  }

  
  changeDay(move){
    this.startDate = new Date(this.startDate + this.offset);
    if (move === 'next'){
      this.startDate.setDate(this.startDate.getDate() + 1);
    }
    else if (move === 'prev'){
      this.startDate.setDate(this.startDate.getDate() - 1);
    }
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
    this.endDateCalc();
    this.initializeView();
  }

  datePicked(datePickerValue): void {
    this.startDate = new Date(datePickerValue);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-d');
    this.endDateCalc();
    this.initializeView();
  }

  dragOver(e) {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }

  drop(e, room) {
    e.preventDefault();

    let guest = e.dataTransfer.getData('guest');
    if (!guest) {
      return;
    }
    guest = JSON.parse(guest);

    const dialogBody =
    {
      title: 'CHANGE ROOM',
      body: `Are you sure you want to change room to: ${room.roomNum}?`,
      confirmBtn: 'Change',
      cancelBtn: 'Cancel'
    };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        const body = {
          checkIn: guest.checkIn,
          checkOut: guest.checkOut,
          roomAllocationId: guest.roomAllocationId, 
          bookingId: guest.bookingId,
          newRoomNumId: room.roomNumId,
          newRoomNum: room.roomNum,
          prevRoomNumId: guest.roomNumId,
          prevRoomNum: guest.roomNum,
          user: this.currentUser
        };
        this.userService.dragDrop(body, this.hotelId).subscribe(
          data => {
            if (data && 'error' in data) {
              this.errorMsg({message: data.error});
              return;
            }
            this.initializeView();
          }
        );
      }
    });
    
    e.dataTransfer.clearData();
  }

  dragStart(e, guest, prevRoom) {
    e.dataTransfer.setData('guest', JSON.stringify({...guest, ...prevRoom}));
  }

  cleanDirtyToggle(room, status): void {
    const dialogBody =
      {title: 'HOUSEKEEPING',
       body: `Change room status to ${status.toUpperCase()}?`,
       confirmBtn: 'Change',
       cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        const body = {roomNumID: room.roomNumId, status, user: this.currentUser, roomNum: room.roomNum};
        this.userService.changeRoomDirty(body, this.hotelId).subscribe(
          data => {
            room.housekeeping = status;
          }, err => {
            this.errorMsg(err);
        });
      }
    });
  }

  goToEdit(roomAllocationId, status, bookingId, source) {
    if (!status) {
      status = 'Unassigned';
    }
    this.dialog.open(EditStayComponent, {data : {
      allocId: roomAllocationId, 
      hotelId: this.hotelId,
      status,
      bookingId,
      source, 
      queryParams: {user: this.currentUser }}
    });
  }

  assignRoom(guest) {
    this.searchActive = false;
    this.assigningGuest = guest;
    this.selectingRoom = true;
  }

  selectRoomToAssign(roomNum, roomNumId, roomId) {
    
    if (this.assigningGuest.roomId !== roomId) {
      this.dialog.open(DialogComponent, {data: {title: 'Oops!', body: 'Room type mismatch', cancelBtn: 'Ok'}});
      return;
    }
    
    const dialogBody =
      {title: 'ROOM ASSIGNMENT',
        body: `Assign to room ${roomNum} ?`,
        confirmBtn: 'Assign',
        cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        const body = {
          roomAllocationId: this.assigningGuest.roomAllocationId, bookingId: this.assigningGuest.bookingId,
          roomNumId, user: this.currentUser, roomNum, check_in: this.assigningGuest.checkIn,
          check_out: this.assigningGuest.checkOut
        };
        this.userService.allocateRoom(body, this.hotelId).subscribe(
          data => {
            if (data && 'error' in data) {
              this.errorMsg({message: data.error});
              return;
            }
            this.initializeView();
          },
          err => {
            this.errorMsg({message: 'Something went wrong. Please refresh your browser and try again'});
          });
        }
    });
    this.selectingRoom = false;
   }

  allocationMenuOpened(guest) {
    if (guest.status === 'Assigned'){
      this.paymentService.getBalanceNonCheckedinAlloc(this.hotelId, guest.roomAllocationId).subscribe(data=>{
        guest['balance'] = data.balance;
        this.commService.allocationMenuOpened(guest);
      })
    }else{
      this.commService.allocationMenuOpened(guest);
    }
  }

  errorMsg(msg) {
    const errorDialog =
      {title: 'Oops !',
      body: msg.message,
      cancelBtn: 'Dismiss'
      };
    this.dialog.open(DialogComponent, {data: errorDialog});
  }

  searchGuest(key: string) {
    this.filteredGuests = [];
    if (!key) {
      return;
    }
    key = key.toLowerCase();
    
    for (const roomType of this.roomsData) {
      for (const ugArr of roomType.unassignedGuests) {
        for (const ug of ugArr?.guests) {
          if (ug?.guestName?.toLowerCase()?.indexOf(key) > -1) {
            this.filteredGuests.push({...ug, roomTypeName: roomType.roomTypeName});
          }
        }
      }
      for (const room of roomType.rooms) {
        for (const guest of room?.allocations) {
          if (guest?.guestName?.toLowerCase()?.indexOf(key) > -1) {
            this.filteredGuests.push(
              {...guest, roomTypeName: roomType.roomTypeName, roomNum: room.roomNum});
          }
        }
      }
    }

  }

  gotoStayview1() {
    this.router.navigate([`/stay-view/${this.hotelId}`], {queryParams: {back: true}});
  }

  goToReservation() {
    this.router.navigate([`/reservation/${this.hotelId}`], {queryParams: {user: this.currentUser}});
  }

  goToExpense() {
    this.router.navigate([`/expenses/${this.hotelId}`]);
  }

  showData(roomNumId: string, roomNum){
    this.userService.getDeviceStatusForRoomNum(this.hotelId, roomNumId).subscribe(
      devicedata => {
        this.dialog.open(LineChartComponent, 
          {data : { queryParams: { user: this.currentUser}, hotelID: this.hotelId, deviceDetails: devicedata, roomNumId: roomNumId, roomNum: roomNum}, width: '60%', height: '70%'});
      }, err => {
        this.errorMsg(err);
      });
  }

  openPrevDayCheckins() {
    const ref = this.dialog.open(PreviousDayCheckinsComponent, {
      width: '950px',
      data: {hotelId: this.hotelId}
    });

    ref.afterClosed().subscribe(res => {
      if (res?.refresh) {
        this.initializeView();
      } else if (res?.assign) {
        this.assignAndCheckIn(res.assign?.guestInfo, res.assign?.room?.roomNumId);
      }
    });
  }

  syncRmsInventory() {
    this.syncing = true;
    this.userService.syncRmsInventory(this.hotelId, this.startDate, this.endDate)
    .pipe(finalize(() => {
      setTimeout(() => {
        this.syncing = false;
      }, 5000);
    }))
    .subscribe(res => {
      if (res?.message) {
        this.dialog.open(DialogComponent, {
          data: {title: 'Success', body: res.message}
        });
      }
    });
  }

  assignAndCheckIn(guestInfo: any, roomNumId: string) {
    const body = {
      roomAllocationId: guestInfo?.roomAllocationId, 
      hotelId: this.hotelId, 
      bookingId: guestInfo?.bookingId, 
      user: this.currentUser,
      allocData: {
        guest_name: guestInfo.guestName,
      },
      previousCheckin: {
        checkIn: guestInfo?.checkIn,
        checkOut: guestInfo?.checkOut,       
        roomNumId
      },
    };
    this.userService.checkIn(body, this.hotelId)
    .subscribe( success => {
      if (success && 'error' in success) {
        const WarningDialog = { title: 'ERROR !', body: `${success.error}`, cancelBtn: 'Dismiss'};
        this.dialog.open(DialogComponent, {data: WarningDialog});
        return;
      }
      this.initializeView();
    },
      err => {});
  }


  // select cells event
  selectCells(event, type: 'down' | 'over' | 'up', cellIndex, roomIndex?, room?, roomTypeId?, roomTypeName?) {
    if (this.quickResDisabled || this.selectingRoom) {
      return;
    }

    const cellDate = this.headerDates[cellIndex];
    if (type === 'down' && room) {
      if (new Date(cellDate) < new Date(this.currentDate)) {
        return;
      }
      this.selectedCell.room = {...room, roomTypeId, roomTypeName};
    } 
  
    if (type === 'down' || this.mouseDown && (type === 'over' || type === 'up')) {
      for (const alloc of this.selectedCell.room?.allocations) {
        const filled = this.isDateInRange(cellDate, alloc.checkIn, alloc.checkOut);
        if (filled) {
          return;
        }
      }
    }

    // click mouse
    if (type === 'down') {
      this.mouseDown = true;
      this.selectedCell.firstCell = cellIndex;
      this.selectedCell.lastCell = cellIndex;
      this.selectedCell.cellIndex[cellIndex] = true;
      this.selectedCell.selectedRow = roomIndex;
    } 

    // drag mouse
    else if (this.mouseDown && type === 'over') {
      if (cellIndex < this.selectedCell.lastCell) {
        this.selectedCell.cellIndex[this.selectedCell.lastCell] = false;
      } 
      else if (cellIndex > this.selectedCell.firstCell) {
        this.selectedCell.cellIndex[cellIndex] = true;
      }
      this.selectedCell.lastCell = cellIndex;
    }

    // release mouse
    else if (this.mouseDown && type === 'up') {
      
      const lastCheckout = this.headerDates.length > this.selectedCell.lastCell + 1 ? this.selectedCell.lastCell + 1 : this.selectedCell.lastCell;
      const reservation = {
        checkin: this.headerDates[this.selectedCell.firstCell],
        checkout: this.headerDates[lastCheckout],
        room: this.selectedCell.room
      }

      this.dialog.open(QuickReservationComponent, {
        data: {
          reservation,
          hotelId: this.hotelId,
          features: this.features?.live
        },
        width: '800px'
      })
      
      this.resetSelectCell();
    }
    
  }

  isDateInRange(cellDate, start, end) {
    const date = new Date(cellDate);
    const startDate = new Date(start);
    const endDate = new Date(end);
  
    return date >= startDate && date < endDate;
  }

  resetSelectCell() {
    this.mouseDown = false;
    this.selectedCell = {
      cellIndex: {},
      selectedRow: -1,
      firstCell: -1,
      lastCell: -1,
      room: {allocations: [], roomNumId: '', roomNum: '', roomTypeId: '', roomTypeName: ''}
    }
  }

  getBalances() {
    this.userService.getBalances(this.checkedInGuests, this.hotelId).subscribe(res => {
      if (res?.success) {
        this.balances = res.data;
      }
    })
  }
}
