<div class="multi-select" [id]="uniqueId" appClickOutside (clickOutside)="showMultiSelect(false)">
    <div class="form-control form-control-sm" (click)="showMultiSelect(true); $event.stopPropagation()">
        {{placeholder}}
    </div>
    <div class="drop-down" *ngIf="isMultiSelectVisible">
        <div class="options" *ngFor="let item of data">
            <label>
                <input type="checkbox" (change)="selectRow($event.target.checked, item)" [(ngModel)]="item.checked">
                {{item[name]}}
            </label>
        </div>
    </div>
</div>