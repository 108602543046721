import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupBookingComponent } from './group-booking.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { CreateGroupBookingModule } from './create-group-booking/create-group-booking.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { AddRoomToGroupComponent } from './add-room-to-group/add-room-to-group.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TransferFolioComponent } from './transfer-folio/transfer-folio.component';


@NgModule({
  declarations: [GroupBookingComponent, AddRoomToGroupComponent, TransferFolioComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    CreateGroupBookingModule,
    TranslateModule,
    MatMenuModule,
    MatDialogModule,
    NgSelectModule,
    MatTooltipModule
  ]
})
export class GroupBookingModule { }
