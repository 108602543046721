import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, key?: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter(it => {
      if (typeof it === 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (typeof it === 'object' && key) {
        return it[key] && it[key].toLowerCase().includes(searchText);
      }
      return false;
    });
  }
}