<div>
    <div class="aio_heading">
      <h2>{{title}}</h2>

        <button mat-raised-button class="primary_btn"
            (click)="goBack()">
            {{'pos.back_btn' | translate}}
        </button>
    </div>
    <mat-divider></mat-divider>
  
    <div class="container-fluid common_content">
        <div class="sections">
            <div>
                <div class="categories">
                    <div *ngFor="let cat of categories" 
                        (click)="getServiceNames(cat)"
                        [ngClass]="{selected: selectedCategory === cat}">
                        {{cat}}
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <input type="search" class="form-control form-control-sm" placeholder="Search..." [(ngModel)]="searchText">
                </div>

                <div class="service_container">
                    <div class="service" *ngFor="let service of serviceNames | filter: searchText"
                        (click)="addService(service)">
                        {{service}}
                    </div>
                </div>

            </div>

            <div class="info_section">
                <form [formGroup]="newOrderForm">
                    <ng-select class="form-control form-control-sm" 
                        (change)="setTable($event)" 
                        [placeholder]="newOrderForm.get('tableNum').value ? '' : 'Select table'"
                        formControlName="tableId"
                        name="staff">
                        <ng-option 
                          *ngFor="let table of tables"
                          [value]="table.id">
                          {{table.tableNum}}
                        </ng-option>
                     </ng-select>

                     <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Staff"
                        name="staff"
                        formControlName="staff"
                    />

                    <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Details"
                        name="staff"
                        formControlName="details"
                    />
                </form>

                <div class="tab_section">
                    <button [ngClass]="{'selected': infoTab === 'services'}"
                        (click)="changeInfoTab('services')">
                        {{'pos.services' | translate}}
                    </button>
                    <button [ngClass]="{'selected': infoTab === 'payments'}"
                        (click)="changeInfoTab('payments')">
                        {{'pos.payments' | translate}}
                    </button>
                </div>
                
                <div class="mid_body">
                    <ng-container *ngIf="infoTab === 'services'">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th *ngIf="orderInfo && orderInfo.status !== 'Billed'">
                                        <input type="checkbox" (change)="toggleSelectAll($event.target.checked)">
                                    </th>
                                    <th>{{'pos.item' | translate}}</th>
                                    <th>{{'pos.qty' | translate}}</th>
                                    <th class="text-right">{{'pos.price' | translate}}</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let service of servicesAdded; let i=index">
                                    <td *ngIf="orderInfo && orderInfo.status !== 'Billed'">
                                        <input type="checkbox" [(ngModel)]="service.selected">
                                    </td>
                                    <td>{{service.service_name}}</td>
                                    <td>
                                        <div class="qty_block">
                                            <button (click)="changeQty(service.service_name, service.quantity-1, i)">-</button>
                                            <input type="text" 
                                                [(ngModel)]="service.quantity"
                                                (keypress)="globalService.numberOnly($event)"
                                                (change)="changeQty(service.service_name, service.quantity, i)"
                                                >
                                            <button (click)="changeQty(service.service_name, service.quantity+1, i)">+</button>
                                        </div>
                                    </td>
                                    <td class="text-right">{{service.total_amount}}</td>
                                    <td>
                                        <div class="remove">
                                            <svg (click)="removeService(service)"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                <path
                                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </ng-container>

                    <ng-container *ngIf="infoTab === 'payments'">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>{{'pos.type_of_payment' | translate}}</th>
                                    <th>{{'pos.comment' | translate}}</th>
                                    <th class="text-right">{{'pos.amount' | translate}}</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let pay of orderInfo?.payments; let i=index">
                                    <td>{{pay.type_of_payment}}</td>
                                    <td>{{pay.comments}}</td>
                                    <td class="text-right">{{pay.amount}}</td>
                                    <td>
                                        <div class="remove">
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                <path
                                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </div>

                <div>
                    <div>{{'pos.balance' | translate}}: {{invoiceBalance | number: '1.2-2'}}</div>
                    <div *ngIf="!orderInfo || orderInfo?.is_complimentary">
                        <input type="checkbox" [(ngModel)]="complimentary" [disabled]="orderInfo && orderInfo.is_complimentary"
                            (change)="complimentaryToggle()">
                        {{'pos.complimentary' | translate}}
                    </div>
                    <div>{{'pos.total' | translate}}: {{invoiceTotal | number: '1.2-2'}}</div>
                </div>

                <div>
                    <!-- VIEW OR EDIT -->
                    <ng-container *ngIf="orderInfo">
                        <button 
                            class="secondary_btn" 
                            mat-raised-button 
                            (click)="goToAddPayment()">
                            {{'pos.payment' | translate}}
                        </button>

                        <!-- ONLY FOR EDIT -->
                        <ng-container *ngIf="orderInfo.status !== 'Billed'">
                            <button class="secondary_btn" mat-raised-button (click)="transferServicesClick()">{{'pos.transfer' | translate}}</button>
                            <button class="secondary_btn" mat-raised-button (click)="transferServicesClick(true)">{{'pos.split_order' | translate}}</button>
                        </ng-container>

                        <button class="secondary_btn" mat-raised-button (click)="printOrder('kot')">{{'pos.print_kot' | translate}}</button>

                        <!-- ONLY FOR BILLED -->
                        <ng-container *ngIf="orderInfo.status === 'Billed'">
                            <button class="secondary_btn" mat-raised-button (click)="printOrder('bill')">{{'pos.print_bill' | translate}}</button>
                            <button class="secondary_btn" mat-raised-button (click)="viewBill()">{{'pos.view_bill' | translate}}</button>
                        </ng-container>
                    
                    </ng-container>

                    <button mat-raised-button *ngIf="orderInfo?.status !== 'Billed'"
                        class="primary_btn"
                        (click)="onSubmit()">
                        {{'pos.submit' | translate}}
                    </button>
                </div>

                <div *ngIf="orderInfo && orderInfo.status !== 'Billed'" class="generate_bill_section">
                    <div [ngStyle]="!generatingBill && {'visibility':'hidden'}"
                        class="discount_sec">
                        <input
                            type="number"
                            class="form-control form-control-sm"
                            min="1"
                            placeholder="Discount"
                            [(ngModel)]="discount.discount"
                        />
                        <select
                            name="discount-type"
                            class="form-control form-control-sm"
                            [(ngModel)]="discount.type"
                            >
                            <option value="%">%</option>
                            <option value="flat">{{ "pos.flat" | translate }}</option>
                        </select>

                        <button (click)="applyDiscount()"
                            mat-raised-button
                            type="button"
                            [disabled]="discount.discountApplied"
                        >
                            {{ "pos.apply" | translate }}
                        </button>
                    </div>

                    <button mat-raised-button class="primary_btn"
                        [ngClass]="{'border border-danger': generatingBill}"
                        (click)="generateBillClick()">
                        {{generatingBill ? 'Confirm Bill' : 'Generate Bill'}}
                    </button>
                </div>

                <div class="printer_section">
                    <div>
                        <label>{{'pos.kot_printer' | translate}}</label>
                        <select class="form-control form-control-sm" [(ngModel)]="selectedPrinter.kot"
                            (change)="setPrinter('kot', $event.target.value)">
                            <option *ngFor="let printer of printers"
                                [value]="printer">
                                {{printer}}
                            </option>
                        </select>
                    </div>

                    <div>
                        <label>{{'pos.bill_printer' | translate}}</label>
                        <select class="form-control form-control-sm" [(ngModel)]="selectedPrinter.bill"
                            (change)="setPrinter('bill', $event.target.value)">
                            <option *ngFor="let printer of printers"
                                [value]="printer">
                                {{printer}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
