import { Injectable } from '@angular/core';

declare var Neodynamic;

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  private _printers = [];
  private _selectedPrinter = '';

  constructor() {
    this.initJSPM();
  }

  set printer(printer) {
    this._selectedPrinter = printer;
  }

  get printers() {
    return this._printers;
  }

  private initJSPM() {
    // WebSocket settings
    JSPM.JSPrintManager.auto_reconnect = false;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = () => {
      if (this.jspmWSStatus()) {
        // get client installed printers
        JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
          this._printers = myPrinters;
        });
      }
    };
  }

  jspmWSStatus(): boolean {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
      // alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
      return false;
    }
  }

  print(cmds) {
    if (!this.jspmWSStatus()) {
      return;
    }
    const cpj = new JSPM.ClientPrintJob();
    cpj.clientPrinter = new JSPM.InstalledPrinter(this._selectedPrinter);
    cpj.printerCommands = cmds;
    cpj.sendToClient();
  }
}
